import axiosInstance from "../lib/axiosInstance.js";

class IncomeBetService {
    #url = "/ab/rest/cpanel/incomeBets";

    getIncomeBetsList(page, searchUser) {
        return axiosInstance.get(this.#url, {
            params: {
                searchUser: searchUser,
                page: page
            }
        })
    }

    getIncomeBet(id) {
        return axiosInstance.get(this.#url+"/"+id)
    }
}

export default new IncomeBetService();