<template>
    <div v-if="null !== this.bet">
        <h3>Входящая ставка #{{bet.id}}</h3>
        <table class="table">
            <tr>
                <th>Автор</th>
                <th>Expari ID</th>
                <th>Матч</th>
                <th>Тип ставки</th>
                <th>Время сбытия</th>
                <th>Создано</th>
            </tr>

            <tr>
                <td>{{bet.userName}}</td>
                <td>{{bet.expariBetId}}</td>
                <td class="text-left">
                    {{bet.sport}}. {{bet.tournamentName}}<br/>
                    {{bet.home}} - {{bet.away}}<br>
                    ({{bet.eventId}})
                </td>
                <td>{{bet.betName}}<br/>{{bet.period}}</td>
                <td>{{bet.playAtFmt}}</td>
                <td>{{bet.createdAtFmt}}</td>
            </tr>
        </table>
    </div>
</template>

<script>

    import IncomeBetService from "../services/IncomeBetService";

    export default {
        name: "IncomeBetsSingle",
        data() {
            return {
                bet: null
            }
        },
        mounted() {
            let self = this;
            if (null != this.$route.params.id) {
                IncomeBetService.getIncomeBet(this.$route.params.id)
                    .then((response) => {
                        self.bet = response.data
                    })
            }
        }
    }
</script>